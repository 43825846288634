@import "../../../theme/index.module.scss";


.SocialMenu {
  display: flex;
  justify-content: center;
  margin: 2.45em 0 2.9em;

  & ul {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include bp($medium) {
      max-width: 400px;
      margin: auto;
    }
    & li {
      display: inline-block;
      width: auto;
    }
  }
}

.MenuItem {
  margin-left: 5px;
}

.socialIcon {
  display: inline-block;
  margin-left: 5px;
  height: 40px;
  width: 40px;
  position: relative;
  text-decoration: none;
  border-radius: 3px;
  text-align: center;
  transform: translate(0, 0);
  transition: all .2s ease;
  cursor: pointer;
  overflow: hidden;
  &:active {
    transform: translate(0, 1px);
  }
}

@include bp($small) {
  .socialIcon {
    margin-left: 10px;
  }
}

.MenuItemFirst {
  composes: MenuItem;
  border-left: none;
  margin-left: 0;
}

.SocialMenu {
  text-align: center;
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
